



































import Vue from 'vue';
import BasketItemResourceViewModel from '@/src/services/viewModel/resource/BasketItemResourceViewModel';
import CreateSubscriptionContractRequestViewModel from '@/src/services/viewModel/resource/CreateSubscriptionContractRequestViewModel';
import SubscriptionArticleResourceViewModel from '../../../../../services/viewModel/resource/SubscriptionArticleResourceViewModel';

export default Vue.extend({
  auth: true,
  hasBackButton: true,
  props: {
    value: { type: Object, default: undefined },
  },
  data: () => ({
    languageItems: [
      {
        id: 'de',
        details: 'DE',
        caption: Vue.$t('app.language.de'),
      },
      {
        id: 'fr',
        details: 'FR',
        caption: Vue.$t('app.language.fr'),
      },
    ],
    isLoading: false,
    subscriptionTypes: [] as SubscriptionArticleResourceViewModel[],
    subscriptionTypesColumns: [
      { value: '', field: 'selection' },
      { value: Vue.$t('page.newGastroJournalSubscription.label.subscription'), field: 'name' },
      { value: Vue.$t('page.newGastroJournalSubscription.label.duration'), field: 'duration' },
      { value: Vue.$t('page.newGastroJournalSubscription.label.priceForYear'), field: 'unitPrice', format: (price: number) => Vue.$format.currency(price, 'CHF') },
    ],
  }),
  computed: {
    selectedSubscription(): BasketItemResourceViewModel {
      const [article] = this.innerValue?.orderBasket?.items ?? [];
      return article;
    },
    selectedSubscriptionId(): string | undefined {
      return this.selectedSubscription?.id;
    },
    innerValue: {
      get(): CreateSubscriptionContractRequestViewModel {
        return this.value ?? {};
      },
      set(value: CreateSubscriptionContractRequestViewModel) {
        this.$emit('input', value);
      },
    },
  },
  async mounted() {
    if (!this.innerValue.languageIso2) {
      this.innerValue.languageIso2 = this.$translation.get()?.toLowerCase();
    }
    await this.getSubscriptonTypes();
  },
  methods: {
    onSubscriptionSelected(selectedRow: {values: SubscriptionArticleResourceViewModel}) {
      const selected = selectedRow?.values;
      const basketItem = new BasketItemResourceViewModel().fromModel(selected);
      basketItem.caption = selected.name;
      basketItem.quantity = 1;
      this.innerValue.orderBasket.items = [basketItem];
      this.innerValue.orderBasket.currency = basketItem.currency;
      this.innerValue.subscriptionTemplateNumber = selected.subscriptionTemplateNumber!;
    },
    async getSubscriptonTypes() {
      this.isLoading = true;
      const response = await this.$service.api.subscriptions.getGastroJournalSubscriptionTypes();
      this.subscriptionTypes = response.items.map((e: any) => ({ ...e }));
      this.isLoading = false;
    },
  },
});
